<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-card-title class="title">
            Fee Offer
            <v-spacer></v-spacer>
            <!-- <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details class="pt-0"></v-text-field> -->
            <transition
              name="animate-css-transition"
              enter-active-class="animated fadeInRight"
              leave-active-class="animated fadeOutRight"
            >
              <!--<add-button permission="fee-offer-create" v-if="student" @action="form.dialog = true">-->
              <!--New Offer-->
              <!--</add-button>-->

              <!--<v-btn outlined @click="form.dialog = true" ma-0 small v-if="this.student">New Offer-->
              <!--</v-btn>-->
            </transition>
          </v-card-title>
          <v-card outlined>
            <v-card-title class="title pb-0">
              <v-flex xs3 sm4>
                <v-select
                  :loading="gradeLoading"
                  :items="grades"
                  class="pa-0"
                  label="Grade"
                  v-model="grade"
                  outlined
                  dense
                />
              </v-flex>
              <v-flex xs3 sm4>
                <v-select
                  :items="sections"
                  class="pa-0"
                  label="Section"
                  v-model="section"
                  :disabled="!grade"
                  outlined
                  dense
                />
              </v-flex>
            </v-card-title>
          </v-card>

          <v-data-table
            :headers="headers"
            :items="form.items.data"
            :options.sync="pagination"
            :footer-props="footerProps"
            :server-items-length="form.items.meta.total"
            :loading="form.loading"
            sort-by="id"
            :expanded.sync="expanded"
            show-expand
            item-key="name"
          >
            <template
              v-slot:item.data-table-expand="{ expand, isExpanded, item }"
            >
              <span v-if="item.fee_offers.length > 0">
                <v-icon
                  style="cursor: pointer"
                  color="error"
                  v-if="isExpanded"
                  @click="expand(!isExpanded)"
                >
                  indeterminate_check_box
                </v-icon>
                <v-icon
                  style="cursor: pointer"
                  color="primary"
                  v-else
                  @click="expand(!isExpanded)"
                >
                  add_circle
                </v-icon>
              </span>
              <span v-else>
                <v-icon style="cursor: no-drop">
                  add_circle
                </v-icon>
              </span>
            </template>
            <template v-slot:item.fee_offer="{ item }">
              {{ item.fee_offers.length }}
              <v-icon small color="success" v-if="item.fee_offers.length > 0"
                >check_circle
              </v-icon>
            </template>
            <template v-slot:item.action="{ item }">
              <add-button
                permission="fee-offer-read"
                @action="addFeeOffer(item)"
                >Add
              </add-button>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-card v-if="item.fee_offers.length > 0" style="width: 100%;">
                  <table
                    width="100%"
                    class="v-datatable v-table theme--light"
                    style="background: #c7c6c63d;"
                  >
                    <thead>
                      <tr>
                        <th class="text-xs-left"><strong>Fee Head</strong></th>
                        <th class="text-xs-left">
                          <strong>Fee Head Amount</strong>
                        </th>
                        <th class="text-xs-left"><strong>Discount </strong></th>
                        <th class="text-xs-left">
                          <strong>Scholarship </strong>
                        </th>
                        <th class="text-xs-right"><strong>Action</strong></th>
                      </tr>
                      <tr
                        v-for="(x, i) in item.fee_offers"
                        :key="i"
                        style="height: 0px"
                      >
                        <td class="text-xs-left">{{ x.title }}</td>
                        <td class="text-xs-left">{{ x.amount.currency() }}</td>
                        <td class="text-xs-left">
                          {{ x.discount_percentage }}%
                          <p>({{ x.discount_amount.currency() }})</p>
                        </td>
                        <td class="text-xs-left">
                          {{ x.scholar_percentage }}%
                          <p>({{ x.scholar_amount.currency() }})</p>
                        </td>
                        <td class="text-xs-right">
                          <edit-button
                            permission="fee-offer-update"
                            @agree="editData(x, item.name, item.enroll_id)"
                          />
                          <delete-button
                            permission="fee-delete"
                            @agree="form.delete(x.id)"
                          />
                        </td>
                      </tr>
                    </thead>
                  </table>
                </v-card>
                <v-card v-else>
                  <v-card-text>
                    No fee Offer
                  </v-card-text>
                </v-card>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>

      <v-dialog v-model="form.dialog" persistent max-width="500px">
        <v-card>
          <v-card-title class="primary white--text">
            <span class="title"
              >Add/Update <small>({{ student_name }})</small></span
            >
          </v-card-title>
          <v-card-text class="pb-1">
            <v-form
              ref="form"
              @submit.prevent="save"
              @keydown.native="form.errors.clear($event.target.name)"
              @keyup.enter="save"
              v-model="valid"
              :lazy-validation="lazy"
            >
              <v-layout row wrap pa-3>
                <v-flex xs12 sm8>
                  <v-select
                    @change="reset()"
                    :items="fees"
                    required
                    class="pa-0 pl-2"
                    label="Select Title*"
                    v-model="form.fee_id"
                    :error-messages="form.errors.get('fee_id')"
                    name="fee_id"
                    :disabled="updateData"
                    outlined
                    dense
                    :rules="[(v) => !!v || 'Title Field is required']"
                  />
                </v-flex>
                <v-flex xs12 sm4>
                  <v-text-field
                    autocomplete="off"
                    label="Amount*"
                    class="pa-0 pl-2"
                    v-model="form.amount"
                    name="discount"
                    :error-messages="form.errors.get('amount')"
                    type="number"
                    :disabled="true"
                    outlined
                    dense
                  />
                </v-flex>
                <v-flex xs12 sm4>
                  <v-text-field
                    autocomplete="off"
                    @keyup="discountPerChange()"
                    label="Discount Percentage(%)*"
                    class="pa-0 pl-2"
                    v-model="form.discount_percentage"
                    name="discount_percentage"
                    :disabled="
                      (form.scholar_percentage.length > 0 &&
                        parseInt(form.scholar_percentage) != 0) ||
                        !form.fee_id
                    "
                    :error-messages="form.errors.get('discount_percentage')"
                    type="number"
                    outlined
                    dense
                  />
                </v-flex>
                <v-flex xs12 sm8>
                  <v-text-field
                    autocomplete="off"
                    label="Discount Amount"
                    @keyup="discountAmountChange()"
                    class="pa-0 pl-2"
                    v-model="form.discount_amount"
                    name="discount_amount"
                    :disabled="
                      (form.scholar_percentage.length > 0 &&
                        parseInt(form.scholar_percentage) != 0) ||
                        !form.fee_id
                    "
                    :error-messages="form.errors.get('discount_amount')"
                    type="number"
                    outlined
                    dense
                  />
                </v-flex>
                <v-flex xs12 sm4>
                  <v-text-field
                    autocomplete="off"
                    :disabled="
                      (form.discount_percentage.length > 0 &&
                        parseInt(form.discount_percentage) != 0) ||
                        !form.fee_id
                    "
                    @keyup="scholarPerChange()"
                    label="Scholar Percentage (%) *"
                    class="pa-0 pl-2"
                    v-model="form.scholar_percentage"
                    name="scholar_percentage"
                    :error-messages="form.errors.get('scholar_percentage')"
                    type="number"
                    outlined
                    dense
                  />
                </v-flex>
                <v-flex xs12 sm8>
                  <v-text-field
                    autocomplete="off"
                    :disabled="
                      (form.discount_percentage.length > 0 &&
                        parseInt(form.discount_percentage) != 0) ||
                        !form.fee_id
                    "
                    label="Scholar Amount"
                    @keyup="scholarAmountChange()"
                    class="pa-0 pl-2"
                    v-model="form.scholar_amount"
                    name="scholar_percentage"
                    :error-messages="form.errors.get('scholar_amount')"
                    type="number"
                    outlined
                    dense
                  />
                </v-flex>
              </v-layout>
              <!--<small>*indicates required field</small>-->
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning" outlined @click="form.dialog = false"
              >Close</v-btn
            >
            <v-btn
              color="success"
              outlined
              @click="validate"
              :disabled="!form.fee_id"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";
import Mixins from "@/library/Mixins";

export default {
  mixins: [Mixins],
  data: () => ({
    valid: true,
    lazy: false,
    gradeLoading: false,
    studentLoading: false,
    form: new Form(
      {
        fee_id: "",
        section_id: "",
        grade_id: "",
        enroll_id: "",
        discount_percentage: 0,
        discount_amount: 0,
        scholar_percentage: 0,
        scholar_amount: 0,
      },
      "/api/fee-offer"
    ),
    search: null,
    headers: [
      { text: "Roll", align: "left", value: "roll", sortable: false },
      {
        text: "Name",
        align: "left",
        value: "name",
        sortable: false,
        width: 300,
      },
      // {text: 'Enroll Id', align: 'left', value: 'enroll_id', sortable: false},
      { text: "Fee Offer", align: "left", value: "fee_offer", sortable: false },
      {
        text: "Action",
        align: "right",
        value: "action",
        sortable: false,
        width: 200,
      },
    ],
    day: "all",
    sections: [],
    grades: [],
    grade: "",
    section: "",
    subjects: [],
    teachers: [{ text: 1, value: 1 }],
    student: "",
    students: [],
    student_name: "",
    fees: [],
    expanded: [],
    updateData: false,
    expand: false,
  }),

  computed: {
    ...mapState(["batch"]),
  },

  mounted() {
    this.getGrades();
  },

  watch: {
    pagination: function() {
      this.get();
    },
    batch: function(value) {
      this.get();
    },
    grade: function() {
      this.section = "";
      this.getSections();
      this.getFeeData();
    },
    section: function() {
      this.get();
    },
    "form.discount_percentage": function(v) {
      if (parseFloat(v) > 0) {
        this.form.scholar_amount = 0;
        this.form.scholar_percentage = 0;
      }

      // this.discountPerChange();
    },
    "form.scholar_percentage": function(v) {
      if (parseFloat(v) > 0) {
        this.form.discount_percentage = 0;
        this.form.discount_amount = 0;
      }
      // this.scholarPerChange();
    },
    "form.scholar_amount": function(v) {
      if (parseFloat(v) > 0) {
        this.form.discount_percentage = 0;
        this.form.discount_amount = 0;
      }
      // this.scholarAmountChange();
    },
    "form.discount_amount": function(v) {
      if (parseFloat(v) > 0) {
        this.form.scholar_amount = 0;
        this.form.scholar_percentage = 0;
      }
      // this.discountAmountChange();
    },
    "form.fee_id": function(v) {
      if (v) {
        this.getAmount();
      }
    },
    search(val) {
      if (!val) {
        this.students = [];
        this.studentLoading = false;
        return;
      }
      if (this.isLoading) return;

      if (val && val.length > 2) {
        this.studentLoading = true;

        this.$rest
          .get(
            "api/search-student?gradeId=" +
              this.grade +
              "&sectionId=" +
              this.section +
              "&search=" +
              this.search.toString()
          )
          .then(({ data }) => {
            this.students = data.data.map((item) => {
              return { value: item.enroll_id, text: item.name };
            });
          })
          .catch((err) => {})
          .finally(() => (this.studentLoading = false));
      }
    },
  },

  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.save();
      }
    },
    get(params) {
      if (this.section && this.day) {
        let extraParams = "sectionId=" + this.section;
        let query = [null, undefined].includes(params)
          ? this.queryString(extraParams)
          : params;
        this.form.get(null, query).then(({ data }) => {
          this.pagination.totalItems = data.meta.total;
        });
      }
    },
    getStudent() {
      // this.$rest.get('/api/search-student?gradeId=' + this.grade + '&sectionId=' + this.section).then(({data}) => {
      //     this.students = data.data.map(item => {
      //         return {value: item.enroll_id, text: item.name}
      //     });
      // });
      // this.$rest.get('/api/student?batchId=' + this.batch.id + '&gradeId=' + this.grade + '&sectionId=' + this.section).then(({data}) => {
      //     this.students = data.data.map(item => {
      //         return {value: item.enroll_id, text: item.name}
      //     });
      // })
    },
    save() {
      this.form.store();
    },

    reset() {
      this.form.discount_percentage = 0;
      this.form.discount_amount = 0;
      this.form.scholar_percentage = 0;
      this.form.scholar_amount = 0;
    },

    getGrades() {
      this.gradeLoading = true;
      this.section = "";
      this.$rest
        .get("/api/grades?rowsPerPage=25&descending=false")
        .then(({ data }) => {
          this.grades = data.data.map((item) => {
            return { value: item.id, text: item.name };
          });
        })
        .finally(() => {
          this.gradeLoading = false;
        });
    },

    getSections() {
      this.student = "";
      this.$rest
        .get("/api/section?batch=" + this.batch.id + "&grade=" + this.grade)
        .then(({ data }) => {
          this.sections = data.data.map((item) => {
            return { value: item.id, text: item.name };
          });
        });
    },

    getFeeData() {
      this.$rest
        .get("/api/fee?batchId=" + this.batch.id + "&gradeId=" + this.grade)
        .then(({ data }) => {
          this.fees = data.data.map((item) => {
            return {
              value: item.id,
              fee_head_id: item.fee_head_id,
              text: item.fee_head.title,
              amount: item.amount,
            };
          });
        });
    },
    getAmount() {
      let filterData = this.fees.filter(
        (item) => item.value === this.form.fee_id
      );
      this.form.amount = filterData[0].amount;
    },
    addFeeOffer(data) {
      this.updateData = false;
      this.student_name = data.name;
      this.form.enroll_id = data.enroll_id;
      this.form.dialog = true;
    },
    editData(data, name, enroll_id) {
      this.student_name = name;
      this.form.enroll_id = enroll_id;
      this.form.fee_id = data.fee_id;
      this.form.id = data.id;
      this.form.discount_percentage = data.discount_percentage;
      this.form.discount_amount = data.discount_amount;
      this.form.scholar_percentage = data.scholar_percentage;
      this.form.scholar_amount = data.scholar_amount;
      this.updateData = true;
      this.getAmount();
      this.form.dialog = true;
    },
    discountPerChange() {
      if (this.form.discount_percentage > 100)
        this.form.discount_percentage = 100;
      this.form.discount_amount = parseFloat(
        (this.form.amount * this.form.discount_percentage) / 100
      );
    },
    discountAmountChange() {
      if (this.form.discount_amount > this.form.amount)
        this.form.discount_amount = this.form.amount;
      let da = this.form.discount_amount;
      let amt = this.form.amount;
      this.form.discount_percentage = parseFloat((da / amt) * 100);
    },
    scholarPerChange() {
      if (this.form.scholar_percentage > 100)
        this.form.scholar_percentage = 100;
      this.form.scholar_amount = parseFloat(
        (this.form.amount * this.form.scholar_percentage) / 100
      ).toFixed();
    },
    scholarAmountChange() {
      if (parseFloat(this.form.scholar_amount) > parseFloat(this.form.amount))
        this.form.scholar_amount = parseFloat(this.form.amount);
      this.form.scholar_percentage = (
        (this.form.scholar_amount / this.form.amount) *
        100
      ).toFixed();
    },
  },
};
</script>
<style lang="scss" scoped>
tr.highlight {
  cursor: pointer !important;
}
</style>
